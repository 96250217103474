const UC_LIVEBUY_SERVICE = 'Livebuy';
const BLACKLIST = ["login", "registrierung", "gastanlage", "rechnungsanschrift", "warenkorb", "bestelluebersicht"];

window.addEventListener("load", initEventHandler);

function initEventHandler() {
    if (typeof window.UC_UI !== "undefined" && window.UC_UI.isInitialized()) {
        const livebuyStatus = UC_UI.getServicesBaseInfo().filter(data => data.name === UC_LIVEBUY_SERVICE)[0];
        if (livebuyStatus && livebuyStatus.consent.status) {
            initLivebuy();
        }
    }
    window.addEventListener('ucEvent', (e) => {
        const { detail } = e;
        const { event } = detail;
        if (event === "consent_status" && detail[UC_LIVEBUY_SERVICE] === true) {
            initLivebuy();
        }
    });
}

async function initLivebuy() {
    if (isWhitelisted()) {
        loadScripts();
    }
}

// Popups nur für Thalia.de und Seiten ausliefern, die nicht auf der Blacklist stehen:
function isWhitelisted() {
    const helper = new DataLayerHelper(newDataLayer);
    const mandantId = Number(helper.get('mandant-id'));
    const seitenTyp = helper.get('seitentyp');
    return mandantId === 2 && !BLACKLIST.includes(seitenTyp);
}

async function loadScripts() {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.setAttribute("red-livebuy-popup", "");
    const snippetIdDiv = buildSnippetIdDiv();
    const warenkorbScript = await fetchWarenkorbScript();
    document.body.appendChild(wrapperDiv);
    wrapperDiv.appendChild(snippetIdDiv);
    render(wrapperDiv, warenkorbScript);
    loadLivebuySDK();
}

function loadLivebuySDK() {
    if (document.querySelector('script[src="https://sdk.livebuy.io/livebuy-sdk.js"]')) {
        return;
    }
    const script = document.createElement('script');
    script.src = 'https://sdk.livebuy.io/livebuy-sdk.js';
    script.setAttribute('data-usercentrics', UC_LIVEBUY_SERVICE);
    script.async = 1;
    document.getElementsByTagName('head')[0].appendChild(script);
}

function buildSnippetIdDiv() {
    const scriptTemplate = document.querySelector('[cms-livebuy-snippet-id]');
    const div = document.createElement('div');
    div.setAttribute('data-livebuy-popup', scriptTemplate.getAttribute('cms-livebuy-snippet-id'));
    return div;
}

async function fetchWarenkorbScript() {
    try {
        const url = new URL('/include/warenkorb/livebuy', window.location.origin);
        const response = await fetch(url);
        return await response.text();
    } catch (e) {
        console.error(e);
    }
}

function render(container, html) {
    const range = document.createRange();
    range.setStart(document.documentElement, 0);
    container.appendChild(range.createContextualFragment(html));
}
